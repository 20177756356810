import React from 'react';
import Box from "@material-ui/core/Box";

function ErrorMessage(props) {

	const { errorCode } = props;

	function getErrorMessage() {
		switch (errorCode) {
			case 'anonymous-auth-failed':
				return 'Anonymous authentication failed. Try again.'
			case 'queued-customer-not-found':
				return 'The customer could not be found. Try creating a new one.';
			case 'queued-customer-get-fail':
				return 'Failed to retrieve the customer. Try again.';
			default:
				return 'Please contact a team member for assistance.';
		}
	}

	return errorCode ? <Box component="p" color="error.main">{getErrorMessage()}</Box> : null;
};

export default ErrorMessage;