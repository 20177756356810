import Box from "@material-ui/core/Box";
import React from "react";
import connectionCheck from "./screens/connectionCheck";
import useTheme from "@material-ui/core/styles/useTheme";
import Typography from "@material-ui/core/Typography";

function AppHeader(props) {

  const theme = useTheme()
  const backgroundColor = props.online ? theme.palette.common.white : theme.palette.secondary.main
  return (
    <Box pl={2} pt={3} pb={3} textAlign="left"  position="relative" style={{backgroundColor:backgroundColor, height:"37px"}}>
      {props.online ?
        <img src="https://www.countdown.co.nz/media/9934/countdown_2018_pos_h_rgb_site.png"  style={{width: "198px"}}/>
        :
        <Box position="absolute" top="0px" bottom="0px" height="30%" m="auto" style={{verticalAlign:"middle"}}>
          <Typography variant="body1" style={{color: theme.palette.common.white}}>
            <b>Please check your network connection.</b>
          </Typography>
        </Box>
      }

    </Box>
  )
}

export default connectionCheck(AppHeader)