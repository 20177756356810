import React, { useEffect, useState } from 'react';
import * as FirestoreService from '../Firebase';
import QueueStatus from '../components/QueueStatus';
import {Box} from "@material-ui/core";

function LineSession(props) {

    const { userId, selectedStore } = props;
    const [userData, setUserData] = useState();

    useEffect(() => {
        FirestoreService.streamButSimple(userId, setUserData);
    }, [props]);

    return (
        <Box ml={2} mr={2} mt={4} textAlign="left">
            <h1>e-queue</h1>
            <Box mt={2} >
            {userData ?
               <QueueStatus user={userData} storeName={selectedStore.store.storeDetail.name}/>
                : null
            }
            </Box>
        </Box>
    );
}

export default LineSession;