import React from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import "./App.css";

import LineSession from "./scenes/LineSession";
import withLogin from './screens/withLogin';


function App({uid, selectedStore}) {

  const userId = uid;

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <div className="App">
            <LineSession
              {...{
                userId, selectedStore
              }}
            />
          </div>
        </Route>
      </Switch>
    </Router>
  );
}

export default withLogin(App);
