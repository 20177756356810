import React from "react";
import Box from "@material-ui/core/Box";
import { Card, Typography, CardContent } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

function Welcome() {
    const classes = useStyles();

    return (
        < div className="Welcome" >
            <Box m={2}>
                <h1>Countdown e-queue</h1>
                <p>Join the queue at your local Countdown from your phone. </p>
                {/* TODO: Change if we ever reopen the queue */}
                <Card className={classes.root}>
                    <CardContent>
                        <Typography variant="h5" color="error" gutterBottom>
                            The trial has now finished, please proceed to store as normal.
                        </Typography>
                    </CardContent>
                </Card>
            </Box>
        </div >
    )
}

export default Welcome;
