import React from "react";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import * as FirestoreService from "../Firebase";
import firebase from "firebase";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import OnlineButton from "./OnlineButton";

// var QRCode = require("qrcode.react");

class QueueStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showRemoveConfirmation: false,
    };
  }

  leaveQueue(customerId) {
    console.log("leave Queue", customerId)
    FirestoreService.removeFromQueue(customerId)
      .then(() => {
        this.signOutAndReload();
      })
      .catch( () => { this.signOutAndReload() });
  }

  async signOutAndReload() {
    await firebase.auth().signOut();
    window.location.reload();
  }

  onClickRemove(){
    let state = this.props.user.state;
    if (state === 'left' || state === 'instore') {
      this.leaveQueue(this.props.user.customerId)
    } else {
      this.setState({ showRemoveConfirmation: true })
    }
  }

  getActionText(state) {
    if (state === 'left') {
      return 'Continue'
    }
    if (state === 'instore') {
      return 'Start again'
    }
    return 'Leave the queue'
  }

  selectTurnRenderer(user) {
    const {position, code, stagingTime, state} = user

    if (state === "left") {
      return <RemoveFromQueue/>
    }

    if (state === "instore") {
      return <InStore code={code}/>
    }

    if (position <= 0 || state === "staging") {
      const isInvite =  (state === "staging" || position === 0)
      const timerStartTime = (isInvite && stagingTime) ? new Date(stagingTime).getTime() + 5000*60 : null
      return <ItsYourTurn timerStartTime={timerStartTime} code={code} />
    }

    if (position === 1) {
      return <YouAreNext/>;
    }

    if (position === 2 || position === 3) {
      return <NextUp position={position}/>
    }

    return <SitBack position={position} />
  }

  render() {
    return (
      <React.Fragment>
        <Box m={2}>
          <h4>{this.props.storeName}</h4>
          <Box textAlign="center">
            <Card variant="outlined">
              { this.selectTurnRenderer(this.props.user) }
            </Card>
            <Box mt={5} textAlign="center" width="100%">
              <OnlineButton variant="contained" onClick={() => this.onClickRemove()} color="secondary" fullWidth={true}>
                { this.getActionText(this.props.user.state) }
              </OnlineButton>
            </Box>
          </Box>
          <ConfirmationDialog
            id="remove-confirmation"
            keepMounted
            open={this.state.showRemoveConfirmation}
            onClose={ () => this.setState({ showRemoveConfirmation: false }) }
            onOk={ () => this.leaveQueue(this.props.user.customerId) }
          />
        </Box>
      </React.Fragment>
    );
  }
}

function ConfirmationDialog(props) {
  const { onClose, onOk, open } = props;

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}>
      <DialogTitle id="confirmation-dialog-title">Confirmation</DialogTitle>
      <DialogContent>Are you sure you want to leave the queue?</DialogContent>
      <DialogActions>
        <OnlineButton variant="contained" autoFocus onClick={ () => onClose()} color="primary">Cancel</OnlineButton>
        <OnlineButton variant="contained" onClick={ () => onOk() } color="primary">Ok</OnlineButton>
      </DialogActions>
    </Dialog>
  )
}

function RemoveFromQueue() {
  return (
      <Box m={2}>
        <Typography variant="h5">
          <b>You've been removed from the queue.</b>
        </Typography>
        <Typography variant="caption" gutterBottom>
          Please join the queue again or speak to a team member.
        </Typography>
      </Box>
  )

}

class Timer extends React.Component {
  constructor({ endDate }) {
    super({ endDate });
    this.state = {
      remainingTime: '5:00' ,
      isFinished: false
    }
  }

  componentDidMount = () => {
    this.timerID = setInterval(
      () => this.tick(),
      1000
    );
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  tick = () => {
    const endDate = this.props.endDate
    const currentDate = new Date()
    if (endDate > currentDate) {
      const remainingTime = new Date(endDate - currentDate)
      const second = remainingTime.getSeconds()
      this.setState({
        remainingTime: remainingTime.getMinutes() + ':' + ((second < 10) ? "0" + second : second),
        isFinished: false
      });
    } else {
      this.setState( {
        isFinished: true
      })
    }
  }

  render() {
    const theme = createMuiTheme()
    return (
      <Box mt={2}>
        {this.state.isFinished ?
          <Typography variant="body1" gutterBottom style={{color:theme.palette.info.main}}>
            We're sorry, your time is up.
          </Typography>
          :
        <Typography variant="body1" gutterBottom style={{color:theme.palette.info.main}}>
          You have {this.state.remainingTime} remaining to enter the store.
        </Typography>
        }
      </Box>
    )
  }
}

function Code(props) {
    const { code } = props
    return (
      <React.Fragment>
      <Box bgcolor="primary.light" pt={2} pb={2}>
        <Typography variant="body1" color="primary"  gutterBottom>
          Your entry code is:
        </Typography>
        <Typography variant="h3" display="block" color="primary"  gutterBottom>
          <b>{code}</b>
        </Typography>
        <Typography variant="body1" color="primary" gutterBottom>
          <b>Please show this code as you enter.</b>
        </Typography>
      </Box>
      </React.Fragment>
    );
}

function NextUp({position}) {
  return (
    <Box ml={2} mr={2} mb={2}>
      <YouArePosition position={position} />
      <Typography variant="body1" display="block" color="primary" gutterBottom>
        Please make your way to the entrance.
      </Typography>
    </Box>
  );
}

function SitBack({position}) {
  return (
    <Box ml={2} mr={2} mb={2}>
      <YouArePosition position={position}/>
      <Box mt={2}>
        <Typography variant="body1" display="block" gutterBottom>
          When it's your turn, you'll have 5 minutes to enter the store.
        </Typography>
      </Box>
    </Box>
  );
}

function YouArePosition( {position}) {
  const suffix = ordinalSuffixOf(position)
  return (
    <React.Fragment>
      <Typography variant="h1" color="primary">
        <b>{position}</b>
      </Typography>
      <Typography variant="body1" gutterBottom>
        You're {position}
        {suffix} in the queue.
      </Typography>
    </React.Fragment>
  );
}

function ordinalSuffixOf(i) {
  let j = i % 10,
    k = i % 100;
  if (j === 1 && k !== 11) {
    return "st";
  }
  if (j === 2 && k !== 12) {
    return "nd";
  }
  if (j === 3 && k !== 13) {
    return "rd";
  }
  return "th";
}

function YouAreNext() {
  return (
    <Box m={2}>
      <Typography variant="h3" color="primary">
        <b>You're next</b>
      </Typography>
      <Box pt={2}>
        <Typography variant="body1" color="primary" gutterBottom>
          Please make yourself known to our team member at the door.
        </Typography>
      </Box>
    </Box>
  );
}

function ItsYourTurn({code, timerStartTime}) {
  return (
    <React.Fragment>
      <Box m={2}>
        <Typography variant="h3">
          <b>It's your turn</b>
        </Typography>
      </Box>
      <Code code={code}/>
      <Box ml={2} mr={2} mb={2}>
        {timerStartTime ? <Timer endDate={new Date(timerStartTime)} /> : null }
      </Box>
    </React.Fragment>

  );
}

function InStore({code}) {
  return (
    <React.Fragment>
      <Box mb={2} mt={2}>
        <Typography variant="h6">
          <b>Thanks for shopping with us</b>
        </Typography>
      </Box>
      <Code code={code}/>
    </React.Fragment>
  )
}

export default QueueStatus;
