import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles';
import yellow from "@material-ui/core/colors/yellow";
import 'typeface-roboto';
import AppHeader from "./AppHeader";

const theme = createMuiTheme({
    palette: {
        primary: { main: '#007837', light: '#edf4db'},
        secondary: {main: '#39464e'},
        info: {main: '#0066B2'},
        warning: yellow,
        error: { main: '#c20e1a'},
        text: {
            primary: '#39464E',
        }
    },
    typography: {
        useNextVariants: true
    },
    overrides: {
        MuiButton: {
            label: {
                color: 'white',
            },
        },
        MuiOutlinedInput: {
            root: {
                background: "white"
            },
        }
    }
});

ReactDOM.render(
    <MuiThemeProvider theme = { theme }>
        <React.Fragment>
            <AppHeader/>
            <App/>
        </React.Fragment>
    </MuiThemeProvider>,
    document.getElementById('root')
);
