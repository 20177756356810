import React from "react";
import Button from "@material-ui/core/Button";
import connectionCheck from "../screens/connectionCheck";

function OnlineButton(props) {

  const {online, ...buttonProps} = props

  return (
    <Button {...buttonProps} disabled={!online || buttonProps.disabled}>
      {props.children}
    </Button>
  )
}

export default connectionCheck(OnlineButton)