import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import { Button, Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import * as FirestoreService from "../Firebase";

function StoreAddress(storeDetail) {
  return (
    <React.Fragment>
      {storeDetail.name}<br />
      {storeDetail.addressLine1}
    </React.Fragment>
  );
}

function StoreSelection(props) {
  const [storeDoc, setStoreDoc] = useState(false);

  useEffect(() => {
    FirestoreService.streamStoreDoc(`${props.selectedStore.store.storeDetail.no}`, setStoreDoc);
  }, [props])

  return (
    <div className="StoreSelection">
      <Box m={2}>
        <Card variant="outlined">
          <Box pb={2} pt={0.5} m={2}>
            <h2>Join the queue</h2>
            <Autocomplete
              id="store-select"
              options={props.supportedStores}
              getOptionLabel={(option) => option.store.storeDetail.name + " - " + option.store.storeDetail.addressLine1}
              renderOption={(option) => (
                StoreAddress(option.store.storeDetail)
              )}
              renderInput={
                (params) =>
                  <TextField {...params} fullWidth={true} label="Select a store" variant="outlined" />
              }
              onChange={(event, value) => {
                props.onStoreChanged(value)
              }}
              defaultValue={props.selectedStore}
            />
            {storeDoc.queueOpen === 'true' ?
              <Box mt={2} mb={2}>
                <Button
                  variant="contained"
                  type="submit"
                  onClick={() => {
                    props.onStoreSelected()
                  }} fullWidth={true} color="primary" disabled={props.selectedStore === null}>
                  Join the queue
              </Button>
              </Box> :
              <Box mt={2} mb={2}>
                <Typography>
                  The equeue is closed for this store, either the store is not open or a physical queue is being used.
                </Typography>
              </Box>
            }
          </Box>
        </Card>
      </Box>
    </div>
  );
}

export default StoreSelection;
