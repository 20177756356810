import React from "react";
import firebase from "firebase";
import { Box, TextField, Link, LinearProgress } from "@material-ui/core";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import ErrorMessage from "./ErrorMessage";
import OnlineButton from "./OnlineButton";

export class JoinQueue extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            name: '',
            phone: '42',
            submitDisable: true,
            error: false,
            loading: false,
        }
    }

    createField = (title, label, note, onChange) => {
        return (
            <Box mt={3} mb={3}>
                <TextField label={title} variant="outlined" value={label} onChange={onChange} fullWidth={true} helperText={note} />
            </Box>
        )
    }

    createUser = () => {
        this.setState({ submitDisable: true, loading: true })
        firebase.auth().signInAnonymously().catch(reason => {
            console.error(reason)
            this.setState({ error: true })
        }).then(data => {
            this.props.signInSuccessCallback(data.user.uid, this.state.name, this.state.phone)
        }).finally(() => {
            this.setState({ submitDisable: true, loading: false })
        })
    }

    updateData = (name, phone) => {
        this.setState({
            name: name,
            phone: phone,
            submitDisable: name.length === 0,
            error: false
        })
    }

    render() {
        const theme = createMuiTheme()
        const submitWidth = (window.innerWidth - 2 * theme.spacing(2)) + "px"
        const submitMargin = theme.spacing(2) + "px"
        return (
            <React.Fragment>
                <Box ml={2} mr={2} mt={2} textAlign="left">
                    {this.state.loading ? <LinearProgress /> : null}
                    <h1>Join the queue</h1>
                    <Link target="_blank" rel="noreferrer" href="https://www.countdown.co.nz/about-us/our-policies/privacy-statement">Countdown privacy policy</Link>
                    <Box mt={7}>
                        {this.createField("Your first name or initials*",
                            this.state.name,
                            "",
                            (event) => {
                                this.updateData(event.target.value, this.state.phone)
                            })
                        }
                        {this.state.error ? <ErrorMessage errorCode="default" /> : null}
                    </Box>
                </Box>
                <Box position="absolute" bottom={submitMargin} left={submitMargin} width={submitWidth}>
                    <OnlineButton variant="contained"  type="submit" onClick={() => { this.createUser()}} fullWidth={true} color="primary" disabled={this.state.submitDisable}>
                        Join the queue
                    </OnlineButton>
                </Box>
            </React.Fragment>
        )
    }
}
