import firebase from "firebase";
import React, { Component } from "react";
import HowTo from "../components/HowTo";
import { JoinQueue } from "../components/JoinQueue";
import StoreSelection from "../components/StoreSelection";
import Welcome from "../components/Welcome";
import * as FirestoreService from "../Firebase";
import stores from "../stores.json";

const Loading = () => <h2>Loading please wait...</h2>;

//TODO : Handle Global error gracefully
const GlobalError = () => (
  <p>An error occured while fetching data. Please refresh the page.</p>
);

function withLogin(WrappedComponent) {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: true,
        error: false,
        selectedStore: stores[0],
        supportedStores: stores,
        isStoreSelected: false
      };
    }

    componentDidMount() {
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          this.subscribeToDocChanges(user.uid);
        } else {
          this.setState({
            loading: false,
          });
        }
      });
      const script = document.createElement("script");
      script.src = "https://nebula-cdn.kampyle.com/wau/68809/onsite/embed.js";
      script.async = true;
      document.body.appendChild(script);
    }

    onStoreChanged(store) {
      if (store !== null) {
        this.setState({
          selectedStore: store,
        });
      } else {
        this.setState({
          isStoreSelected: false,
        });
      }
    }

    onStoreSelected() {
      this.setState({
        isStoreSelected: true,
      });
    }

    onLogin(uid, name, phone, selectedStore) {
      FirestoreService.createQueueSession(
        uid,
        selectedStore.store.storeDetail.no,
        name,
        phone
      )
        .then(() => {
          this.subscribeToDocChanges(uid);
        })
        .catch((error) => {
          this.setState({
            error: true,
            loading: false,
            queuedCustomer: null,
          });
        });
    }

    subscribeToDocChanges(uid) {
      this.unsubscribe = FirestoreService.getUserDoc(uid).onSnapshot((doc) => {
        if (doc.exists) {
          let storeToSelect = this.state.supportedStores.find(
            (s) => `${s.store.storeDetail.no}` === doc.data().storeId
          );

          this.setState({
            uid: doc.data().customerId,
            loading: false,
            selectedStore: storeToSelect,
            isStoreSelected: true,
          });
        } else {
          this.setState({
            loading: false,
            uid: null,
          });
        }
      });
    }

    componentWillUnmount() {
      try {
        this.unsubscribe && this.unsubscribe();
      } catch (e) {
        console.error(e);
      }
    }

    render() {
      // while checking user session, show "loading" message
      if (this.state.error) return <GlobalError />;
      if (this.state.loading) return <Loading />;
      if (!this.state.isStoreSelected && !this.state.uid)
        return (
          <div>
            <Welcome />
            <StoreSelection
              selectedStore={this.state.selectedStore}
              supportedStores={this.state.supportedStores}
              onStoreChanged={(store) => {
                console.log("Store changed:");
                console.log(store);
                this.onStoreChanged(store);
              }}
              onStoreSelected={() => this.onStoreSelected()}
            />
            <HowTo />
          </div>
        );
      if (!this.state.uid)
        return (
          <JoinQueue
            signInSuccessCallback={(uid, name, phone) => {
              this.onLogin(uid, name, phone, this.state.selectedStore);
            }}
          />
        );
      // otherwise, show the desired route
      return (
        <WrappedComponent
          {...this.props}
          uid={this.state.uid}
          selectedStore={this.state.selectedStore}
        />
      );
    }
  };
}

export default withLogin;
