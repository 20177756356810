import React from "react";
import {Detector} from "react-detect-offline";

export default function connectionCheck(WrappedComponent) {
  return class extends React.Component {
    render() {
      return (
        <Detector render={({online}) => (
          <WrappedComponent {...this.props} online={online}/>
        )}/>
      )
    }
  }
}