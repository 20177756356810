import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/functions";

const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID
};

firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

export const getQueuedCustomer = userId => {
	return db.collection('QueuedCustomer')
		.doc(userId)
		.get();
};

export const createQueueSession = async (userId, storeId, userName, phone) => {
	let addToQueue = firebase.functions().httpsCallable('addToQueue');
	return await addToQueue({
		"customerId": userId,
		"fullName": userName,
		"phone": phone,
		"storeId": `${storeId}`
	});
};


export const removeFromQueue = async (userId) => {
	let removeFromQueue = firebase.functions().httpsCallable('removeFromQueue');
	return await removeFromQueue({
		"customerId": userId
	});
};

export const signUp = async (fullName, email, password) => {
	let signUp = firebase.functions().httpsCallable('signUp');
	return await signUp({
		"fullName": fullName,
		"email": email,
		"password": password
	});
}


export const streamQueuedCustomerData = (userId, observer) => {
	return db.collection('QueuedCustomer')
		.doc(userId)
		.onSnapshot(observer).then((snap) => {
			console.log(snap.data());
		});
};

export const streamButSimple = (userId, setUserData) => {
	db.collection("QueuedCustomer").doc(userId)
		.onSnapshot(function (doc) {
			console.log("Current data: ", doc.data());
			setUserData(doc.data());
		});
}

export const getUserDoc = (userId) => {
	return db.collection("QueuedCustomer").doc(userId);
}

export const streamStoreDoc = (storeId, setStoreDoc) => {
	db.collection("StoreInfo")
    .doc(storeId)
    .onSnapshot(snapshot => {
			console.log(`Store Data: `, snapshot.data());
			setStoreDoc(snapshot.data());
		});
}