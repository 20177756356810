import React from "react";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

class HowTo extends React.Component {
    render() {
        return (
            <div className="HowTo">
                <Box>
                  <Box ml={2} pt={0.5}>
                    <h2>How it works</h2>
                  </Box>
                  <Box pb={2}>
                    <List>
                      <ListItem>1. Enter your contact details.</ListItem>
                      <ListItem>2. Join the queue and keep an eye on your position in line. You're free to take a short walk or wait in your car.</ListItem>
                      <ListItem>3. When it’s your turn, you’ll have 5 minutes to enter. We’ll call your name when it’s
                        your turn.</ListItem>
                      <ListItem>4. Please show this code at the check-in near the entrance.</ListItem>
                    </List>
                  </Box>
                </Box>
            </div>
        );
    }
}

export default HowTo